import { cssColor } from '@odo/utils/css-color';
import { Flex } from '@odo/components/elements/layout/flex';
import Button from '@odo/components/elements/button';
import { NavItem } from '@odo/components/layouts/app-container';
import {
  FaCopy as IconDuplicate,
  FaCalculator as IconCalculator,
} from 'react-icons/fa';
import { useCurrentProduct } from '@odo/contexts/product-editor';
import { getAdminUrl } from '@odo/screens/deal/editor/helpers';
import { Status } from '@odo/screens/deal/editor/types';
import ResetButton from './reset-button';
import StatusButton from './status-button';
import SaveButton from './save-button';
import { useValidity } from '@odo/screens/deal/editor/hooks';

const Toolbar = () => {
  const currentProduct = useCurrentProduct();
  const validity = useValidity();

  return (
    <Flex flexDirection="row" gap={[2, 3]}>
      {!!currentProduct?.id && (
        <>
          <NavItem justifyContent="center" height="42px" width="42px">
            <Button
              hue="grey"
              variant="flat"
              circular
              px={1}
              py={1}
              // TODO: deal duplication
              onClick={() => window.alert('Duplication feature pending...')}
            >
              <IconDuplicate size={16} color={cssColor('grey')} />
            </Button>
          </NavItem>

          <a
            href={getAdminUrl({
              dest: 'priceCalculator',
              productId: currentProduct.id,
            })}
            target="_blank"
            rel="noreferrer"
          >
            <NavItem justifyContent="center" height="42px" width="42px">
              <Button hue="grey" variant="flat" circular px={1} py={1}>
                <IconCalculator size={16} color={cssColor('grey')} />
              </Button>
            </NavItem>
          </a>
        </>
      )}

      <NavItem justifyContent="center" height="42px">
        <ResetButton />
        <SaveButton status={validity?.status} />
      </NavItem>

      <NavItem
        px={0}
        py={0}
        height="42px"
        width={validity?.status === Status.valid ? '42px' : undefined}
        justifyContent={
          validity?.status === Status.valid ? 'center' : 'space-between'
        }
      >
        <StatusButton validity={validity} />
      </NavItem>
    </Flex>
  );
};

export default Toolbar;
