import { Flex } from '@odo/components/elements/layout';
import { Text } from '@odo/components/elements/typography';
import { useCurrentProduct } from '@odo/contexts/product-editor';
import { SkuAvailability } from '@odo/types/portal';
import { ReactComponent as IconValid } from '@odo/assets/svg/check-circle.svg';
import { ReactComponent as IconExclamation } from '@odo/assets/svg/exclamation-circle.svg';
import { ReactComponent as IconSpinner } from '@odo/assets/svg/tube-spinner.svg';
import { cssColor } from '@odo/utils/css-color';
import type { FunctionComponent, SVGProps } from 'react';

interface StatusProps {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  color: string;
  message: string;
}

const statusConfig: Record<SkuAvailability, StatusProps> = {
  [SkuAvailability.owned]: {
    icon: IconValid,
    color: cssColor('palette-turquoise'),
    message: 'SKU is already saved in magento.',
  },
  [SkuAvailability.available]: {
    icon: IconValid,
    color: cssColor('palette-turquoise'),
    message: 'SKU is available.',
  },
  [SkuAvailability.taken]: {
    icon: IconExclamation,
    color: cssColor('palette-pink'),
    message: 'SKU already in use, please select another.',
  },
  [SkuAvailability.checking]: {
    icon: IconSpinner,
    color: cssColor('palette-blue'),
    message: 'Checking availability...',
  },
  [SkuAvailability.validationFailed]: {
    icon: IconExclamation,
    color: cssColor('palette-yellow'),
    message: 'Could not validate SKU, please try again.',
  },
};

const Status = ({ icon: Icon, color, message }: StatusProps) => (
  <Flex gap={2}>
    <Icon color={color} width={16} height={16} />
    <Text color={color} fontWeight={700}>
      {message}
    </Text>
  </Flex>
);

const SkuStatus = () => {
  const { skuAvailability } = useCurrentProduct();
  const status = skuAvailability && statusConfig[skuAvailability];

  if (!status) return null;
  return <Status {...status} />;
};

export default SkuStatus;
