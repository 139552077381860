/**
 * NOTE: this is a duplicate of `@odo/graphql/product.ts` but with the new types.
 * TODO: rename this file to `product.ts` and remove the old one once we no longer use it.
 */
import { createClient } from '@odo/services/urql';
import type {
  MutationCreateProductOutput,
  MutationUpdateProductQuantityArgs,
  MutationUpdateProductQuantityOutput,
  ProductInventory,
  QueryProductArgs,
  QueryProductsBySKUArgs,
} from '@odo/types/api';
import type {
  CreateProductInput,
  GetProductInterface,
  UpdateProductInput,
} from '@odo/types/api-new';
import { throwGraphQLError } from '@odo/utils/graphql';
import { gql } from 'urql';

const PRODUCT_FRAGMENT = gql`
  fragment ProductFragment on Product {
    id
    buyer
    salesAssistant
    supplier
    isSupplierNew
    type
    brand
    sku
    url
    name
    shortName
    price
    cost
    rebateDiscount
    adminCost
    isDisplayRetail
    retail
    isSavingsInRands
    activeFromDate
    activeToDate
    area
    isAlcoholic
    isHygienic
    isParallelImport
    isReferable
    additionalInfo
    calloutText
    lockdownText
    condition
    isFragile
    isReturnableToSupplier
    warrantyPeriod
    warranty
    features
    videos
    leftAdditionalInfo
    moreDetails
    status
    taxClass
    isLunchtimeProduct
    isBestSeller
    platform
    isMainDeal
    campaign
    campaignMailer
    applyMap
    isShippingApplied
    width
    length
    height
    weight
    isShippedIndividually
    isSampleReceived
    isPhotographedByStudio
    pillOne
    pillTwo
    shippingCost
    isDeliveredBySupplier
    supplierRepacks
    originalStock
    surcharge
    surcharges {
      key
      value
    }
    priority
    isPreviewOnly
    dealType
    preview
    buyInStockType
    customerDeliveryTime
    # NOTE: we aren't loading all of the inventory fields here
    # TODO: confirm if we can leave out the missing fields
    inventory {
      id
      qty
      isInStock
      minSaleQuantity
      useConfigMinSaleQty
      maximumSaleQuantity
      useConfigMaxSaleQty
      isApplyMaxSaleQtyCustomerProfile
      isApplyMaxSaleQtyToProductOptions
    }
    categories {
      categoryId
      categoryName
    }
    images {
      id
      filePath
      url
      position
      label
      imageTypes
      excludeImageTypes
    }
    sizeChart {
      id
      recommendation
      measurement
      mobile {
        url
        filePath
      }
      tablet {
        url
        filePath
      }
      desktop {
        url
        filePath
      }
    }
  }
`;

const GET_PRODUCT = gql`
  ${PRODUCT_FRAGMENT}
  query getProduct($id: ID!) {
    getProduct(productId: $id) {
      ...ProductFragment
    }
  }
`;

const UPDATE_PRODUCT = gql`
  ${PRODUCT_FRAGMENT}
  mutation updateProduct($productId: ID!, $input: UpdateProduct!) {
    updateProduct(productId: $productId, input: $input) {
      ... on ResponseMessage {
        code
        message
      }
      ... on Product {
        ...ProductFragment
      }
    }
  }
`;

const UPDATE_PRODUCT_QUANTITY = gql`
  mutation updateProductQuantity($stockId: ID!, $qty: Int!) {
    updateInventoryItem(stockId: $stockId, inventoryItem: { qty: $qty }) {
      ... on ResponseMessage {
        status
        code
        message
      }
    }
  }
`;

const CREATE_PRODUCT = gql`
  ${PRODUCT_FRAGMENT}
  mutation createProduct($input: CreateProduct!) {
    createProduct(input: $input) {
      ... on ResponseMessage {
        code
        message
      }
      ... on Product {
        id
        ...ProductFragment
      }
    }
  }
`;

const GET_PRODUCTS_BY_SKU = gql`
  query getProductBySKU($filter: InputProductFilter!) {
    getProducts(filter: $filter) {
      id
    }
  }
`;

interface ClientParams {
  signal?: AbortSignal;
}

type QueryProductParams = QueryProductArgs & ClientParams;

interface QueryProductOutput {
  getProduct: GetProductInterface;
}

type QueryProductsBySKUParams = {
  sku: string;
  id?: GetProductInterface['id'];
} & ClientParams;

interface QueryProductsBySKUOutput {
  getProducts: Pick<GetProductInterface, 'id'>[];
}

type MutationUpdateProductParams = {
  id: MutationUpdateProductArgs['productId'];
  product: MutationUpdateProductArgs['input'];
} & ClientParams;

type MutationUpdateProductQuantity = {
  stockId: ProductInventory['id'];
  quantity: ProductInventory['qty'];
} & ClientParams;

type MutationCreateProductParams = {
  product: MutationCreateProductArgs['input'];
} & ClientParams;

/**
 * Product query.
 */
export const queryProduct = async ({ id, signal }: QueryProductParams) => {
  const { data, error } = await createClient({ signal })
    .query<QueryProductOutput, QueryProductArgs>(
      GET_PRODUCT,
      { id },
      { requestPolicy: 'network-only' }
    )
    .toPromise();

  // only throw errors if we don't get any data back
  if (!(data && data.getProduct) && error) {
    throwGraphQLError(error);
  }

  return data && (data.getProduct || undefined);
};

/**
 * Get product by SKU query.
 */
export const queryProductBySKU = async ({
  sku,
  signal,
}: QueryProductsBySKUParams) => {
  const { data, error } = await createClient({ signal })
    .query<QueryProductsBySKUOutput, QueryProductsBySKUArgs>(
      GET_PRODUCTS_BY_SKU,
      { filter: { select: [{ field: 'SKU', condition: 'IN', value: sku }] } },
      { requestPolicy: 'network-only' }
    )
    .toPromise();

  // only throw errors if we don't get any data back
  if (!(data && data.getProducts) && error) {
    throwGraphQLError(error);
  }

  return data && data.getProducts;
};

export interface MutationUpdateProductArgs {
  productId: GetProductInterface['id'];
  input: UpdateProductInput;
}

interface MutationUpdateProductOutput {
  updateProduct: GetProductInterface;
}

/**
 * Update product mutation.
 */
export const mutationUpdateProduct = async ({
  id,
  product,
  signal,
}: MutationUpdateProductParams) => {
  const { data, error } = await createClient({ signal })
    .mutation<MutationUpdateProductOutput, MutationUpdateProductArgs>(
      UPDATE_PRODUCT,
      { productId: id, input: product }
    )
    .toPromise();

  // only throw errors if we don't get any data back
  if (!(data && data.updateProduct) && error) {
    throwGraphQLError(error);
  }

  return data && (data.updateProduct || undefined);
};

/**
 * Update product quantity mutation.
 */
export const mutationUpdateProductQuantity = async ({
  stockId,
  quantity,
  signal,
}: MutationUpdateProductQuantity) => {
  const { data, error } = await createClient({ signal })
    .mutation<
      MutationUpdateProductQuantityOutput,
      MutationUpdateProductQuantityArgs
    >(UPDATE_PRODUCT_QUANTITY, {
      stockId,
      qty: quantity,
    })
    .toPromise();

  // only throw errors if we don't get any data back
  if (!(data && data.updateInventoryItem) && error) {
    throwGraphQLError(error);
  }

  return data && (data.updateInventoryItem || false);
};

export interface MutationCreateProductArgs {
  input: CreateProductInput;
}

/**
 * Create product mutation.
 */
export const mutationCreateProduct = async ({
  product,
  signal,
}: MutationCreateProductParams) => {
  const { data, error } = await createClient({ signal })
    .mutation<MutationCreateProductOutput, MutationCreateProductArgs>(
      CREATE_PRODUCT,
      { input: product }
    )
    .toPromise();

  // only throw errors if we don't get any data back
  if (!(data && data.createProduct) && error) {
    throwGraphQLError(error);
  }

  return data && (data.createProduct || undefined);
};
