import { cssColor } from '@odo/utils/css-color';
import type {
  BackgroundColorProps,
  BorderProps,
  LayoutProps,
} from '@odo/lib/styled';
import styled, { border, color, compose, layout } from '@odo/lib/styled';
import type { ReactNode } from 'react';
import { useEffect, useRef, useState } from 'react';
import type { Placement, OffsetOptions, ShiftOptions } from '@floating-ui/dom';
import {
  autoUpdate,
  computePosition,
  offset,
  flip,
  shift,
  arrow,
} from '@floating-ui/dom';

const BORDER_WIDTH = 4;
const MAX_WIDTH = 250;

type TooltipInnerProps = BorderProps & LayoutProps;

const TooltipInner = styled.div<TooltipInnerProps>`
  box-sizing: border-box;
  position: absolute;
  width: max-content;
  top: 0;
  left: 0;

  background: ${cssColor('dark-grey')};
  color: ${cssColor('white')};
  border-radius: 0.75rem;
  padding: 0.75rem 1rem;

  text-align: center;

  pointer-events: none;

  z-index: 15;

  opacity: 0;
  transition: transform 150ms ease, opacity 75ms ease;

  &.placement-top {
    transform: translateY(15px);
    border-top-width: 0;
    border-right-width: 0;
    border-left-width: 0;
  }
  &.placement-bottom {
    transform: translateY(-15px);
    border-right-width: 0;
    border-bottom-width: 0;
    border-left-width: 0;
  }
  &.placement-left {
    transform: translateX(15px);
    border-top-width: 0;
    border-bottom-width: 0;
    border-left-width: 0;
  }
  &.placement-right {
    transform: translateX(-15px);
    border-top-width: 0;
    border-right-width: 0;
    border-bottom-width: 0;
  }

  &.active {
    opacity: 1;

    &.placement-top,
    &.placement-bottom {
      transform: translateY(0);
    }
    &.placement-left,
    &.placement-right {
      transform: translateX(0);
    }
  }

  ${compose(border, layout)}
`;

TooltipInner.defaultProps = {
  borderWidth: BORDER_WIDTH,
  borderStyle: 'solid',
  borderColor: cssColor('palette-blue'),
  maxWidth: MAX_WIDTH,
};

const ARROW_SIZE = 8;

type TooltipArrowProps = BackgroundColorProps;

const TooltipArrow = styled.span<TooltipArrowProps>`
  position: absolute;
  width: ${ARROW_SIZE}px;
  height: ${ARROW_SIZE}px;
  clip-path: polygon(50% 50%, 0% 100%, 100% 100%);

  ${compose(color)}
`;

TooltipArrow.defaultProps = {
  backgroundColor: cssColor('palette-blue'),
};

type Side = 'top' | 'right' | 'bottom' | 'left';

interface ArrowPlacement {
  static: Side;
  transform: string;
}

/**
 * NOTE: when placing our arrow we need to give it the correct side and rotation.
 */
const arrowPlacementMap: Record<Side, ArrowPlacement> = {
  top: { static: 'bottom', transform: 'rotate(180deg)' },
  right: { static: 'left', transform: 'rotate(-90deg)' },
  bottom: { static: 'top', transform: 'rotate(0deg)' },
  left: { static: 'right', transform: 'rotate(90deg)' },
};

const Tooltip = ({
  children,
  content,
  placement: placementProp = 'bottom',
  offset: offsetProp = 8,
  shift: shiftProp = { padding: 5 },
  disabled,
  color,
  showDelay,
  hideDelay,
  maxWidth,
}: {
  children: ReactNode;
  content: () => ReactNode;
  placement?: Placement;
  offset?: OffsetOptions;
  shift?: ShiftOptions;
  disabled?: boolean;
  color?: string;
  showDelay?: number;
  hideDelay?: number;
  maxWidth?: number;
}) => {
  const anchorRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const tooltipArrowRef = useRef<HTMLDivElement>(null);
  const cleanupRef = useRef<(() => void) | undefined>();

  const [show, setShow] = useState(false);

  /**
   * Hide the tooltip and cleanup when disabled.
   */
  useEffect(() => {
    if (disabled) {
      cleanupRef.current && cleanupRef.current();
      setShow(false);
    }
  }, [disabled]);

  /**
   * Show/hide state toggle and exit animation.
   */
  useEffect(() => {
    if (!anchorRef.current || disabled) return;

    const anchor = anchorRef.current;

    let showTimeout: NodeJS.Timeout | undefined;
    let hideTimeout: NodeJS.Timeout | undefined;

    // delays, add to DOM via state, then placement and enter animation in side effect.
    const showTooltip = () => {
      hideTimeout && clearTimeout(hideTimeout);

      const show = () => setShow(true);

      if (showDelay) {
        /**
         * NOTE: if the children change while the mouse is over the anchor element (eg. after clicking),
         * the mouseenter event could get triggered again without the mouseleave event triggering.
         * If we don't clear the previous timeout before adding a new one, then it won't be able to be cleared later.
         * Potentially resulting in our tooltip showing after our delay, even if the mouse has left the anchor element.
         */
        showTimeout && clearTimeout(showTimeout);

        showTimeout = setTimeout(show, showDelay);
      } else {
        show();
      }
    };

    // delays, exit animation, then remove from DOM via state.
    const hideTooltip = () => {
      showTimeout && clearTimeout(showTimeout);

      const hide = () => {
        if (tooltipRef.current) {
          tooltipRef.current.classList.remove('active');
        }

        cleanupRef.current && cleanupRef.current();
        cleanupRef.current = undefined;

        // wait for transition to finish, then remove
        setTimeout(() => setShow(false), 150);
      };

      if (hideDelay) {
        /**
         * NOTE: there's an interesting scenario with the mouse events,
         * which requires it to clear the previous showTimeout before adding a new one.
         * Just to be safe, we're gonna do a cleanup of the hideTimeout here as well.
         */
        hideTimeout && clearTimeout(hideTimeout);

        hideTimeout = setTimeout(hide, hideDelay);
      } else {
        hide();
      }
    };

    const eventList: [string, () => void][] = [
      ['mouseenter', showTooltip],
      ['mouseleave', hideTooltip],
      ['focus', showTooltip],
      ['blur', hideTooltip],
    ];

    eventList.forEach(([event, listener]) =>
      anchor.addEventListener(event, listener)
    );

    return () => {
      eventList.forEach(([event, listener]) =>
        anchor.removeEventListener(event, listener)
      );

      showTimeout && clearTimeout(showTimeout);
      hideTimeout && clearTimeout(hideTimeout);
    };
  }, [disabled, showDelay, hideDelay]);

  /**
   * Placement bindings and enter animation.
   * NOTE: We want to wait for the DOM node to be rendered before trying to animate it.
   */
  useEffect(() => {
    if (
      !anchorRef.current ||
      !tooltipRef.current ||
      !tooltipArrowRef.current ||
      !show
    ) {
      return;
    }

    const anchor = anchorRef.current;
    const tooltip = tooltipRef.current;
    const tooltipArrow = tooltipArrowRef.current;

    let timeoutId: NodeJS.Timeout | undefined;

    cleanupRef.current = autoUpdate(anchor, tooltip, () => {
      computePosition(anchor, tooltip, {
        placement: placementProp,
        /**
         * We might want a piece of middleware to prevent tooltips from floating over the header/nav.
         * If so, we can look into using the below:
         * @see https://floating-ui.com/docs/detectOverflow
         */
        middleware: [
          offset(offsetProp),
          flip(),
          shift(shiftProp),
          arrow({ element: tooltipArrow }),
        ],
      }).then(({ x, y, placement, middlewareData }) => {
        const [primaryPlacement] = placement.split('-');

        if (placement !== placementProp) {
          tooltip.classList.remove('placement-top');
          tooltip.classList.remove('placement-right');
          tooltip.classList.remove('placement-bottom');
          tooltip.classList.remove('placement-left');
          tooltip.classList.add(`placement-${primaryPlacement}`);
        }

        /**
         * TODO: look into using transform properties instead:
         * @see https://floating-ui.com/docs/misc#subpixel-and-accelerated-positioning
         */
        Object.assign(tooltip.style, {
          left: `${x}px`,
          top: `${y}px`,
        });

        if (middlewareData.arrow) {
          const arrowX = middlewareData.arrow.x;
          const arrowY = middlewareData.arrow.y;

          const side: ArrowPlacement | undefined =
            primaryPlacement in arrowPlacementMap
              ? arrowPlacementMap[primaryPlacement]
              : undefined;

          const nextArrowStyle = {
            left: arrowX != null ? `${arrowX}px` : '',
            top: arrowY != null ? `${arrowY}px` : '',
            right: '',
            bottom: '',
            ...(typeof side !== 'undefined' && {
              [side.static]: `-${BORDER_WIDTH + ARROW_SIZE}px`,
              transform: side.transform,
            }),
          };

          Object.assign(tooltipArrow.style, nextArrowStyle);
        }

        // enter animation
        timeoutId = setTimeout(
          () => tooltip.classList.add('active'),
          // NOTE: we add an extra 100ms for our placement class changes to come into effect
          100
        );
      });
    });

    return () => {
      cleanupRef.current && cleanupRef.current();
      timeoutId && clearTimeout(timeoutId);
    };
  }, [show, placementProp, offsetProp, shiftProp]);

  return (
    <>
      <div ref={anchorRef} style={{ display: 'inline-flex' }}>
        {children}
      </div>

      {show && (
        <TooltipInner
          maxWidth={maxWidth}
          ref={tooltipRef}
          className={`placement-${placementProp}`}
          borderColor={color}
        >
          {content()}
          <TooltipArrow ref={tooltipArrowRef} backgroundColor={color} />
        </TooltipInner>
      )}
    </>
  );
};

export default Tooltip;
