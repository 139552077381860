import { cssColor } from '@odo/utils/css-color';
import { Flex } from '@odo/components/elements/layout/flex';
import Button from '@odo/components/elements/button';
import Dropdown from '@odo/components/widgets/dropdown';
import { FaChevronDown as IconDown } from 'react-icons/fa';
// custom icons stolen from elsewhere coz FA icons weren't cutting it
import { ReactComponent as IconValid } from '@odo/assets/svg/check-circle.svg';
import { ReactComponent as IconExclamation } from '@odo/assets/svg/exclamation-circle.svg';
import type { Validity } from '@odo/screens/deal/editor/helpers';
import { Status } from '@odo/screens/deal/editor/types';
import StatusMessages from '@odo/screens/deal/editor/widgets/status-messages';

const StatusButton = ({ validity }: { validity: Validity | undefined }) => {
  if (!validity?.status) return null;

  const { status, errors, warnings } = validity;

  return (
    <Dropdown
      offset={{ crossAxis: 8, mainAxis: 8 }}
      border="1px solid"
      borderColor={cssColor('border')}
      minWidth="200px"
      placement="bottom-end"
      disabled={!(errors.length > 0 || warnings.length > 0)}
      content={() => (
        <Flex px="12px" py="12px" gap={3} flexDirection="column">
          <StatusMessages validity={validity} />
        </Flex>
      )}
    >
      <Button
        hue={
          status === Status.valid
            ? 'turquoise'
            : status === Status.error
            ? 'pink'
            : 'yellow'
        }
        variant="flat"
        px={1}
        py={1}
      >
        {status === Status.error && (
          <>
            <IconExclamation width={26} height={26} />
            <IconDown size={14} color={cssColor('grey-blue')} />
          </>
        )}

        {status === Status.warning && (
          <>
            <IconExclamation width={26} height={26} />
            <IconDown size={14} color={cssColor('grey-blue')} />
          </>
        )}

        {status === Status.valid && <IconValid width={28} height={28} />}
      </Button>
    </Dropdown>
  );
};

export default StatusButton;
