import type { AttributeOption } from '@odo/contexts/attributes';
import type { EditorProductInterface } from '@odo/types/portal';

export type SupplierOptionType = AttributeOption & { id: string };

export const attributeToSupplierOption = (
  attribute: AttributeOption
): SupplierOptionType => ({ ...attribute, id: attribute.key });

export const getSupplierOptionLabel = (supplierOption: SupplierOptionType) => {
  const parts = [supplierOption.originalData.value, `ID: ${supplierOption.id}`];

  const tradingName = (supplierOption.metadata || []).find(
    ({ key }) => key === 'trading_name'
  );
  if (tradingName && tradingName.value) parts.push(tradingName.value);

  return parts.join(' | ');
};

/**
 * NOTE: biz seems to not want this data shown. But not sure why it's there.
 * Leaving this in here for now in case there was some confusion and we need it later.
 */
export const getSupplierOptionBuyer = (supplierOption: SupplierOptionType) =>
  supplierOption?.metadata?.find(meta => meta.key === 'buyer')?.value;

export const getSupplierFromOption = (
  supplierOption: SupplierOptionType
): EditorProductInterface['supplier'] => ({
  id: supplierOption.id,
  label: getSupplierOptionLabel(supplierOption),
});
