import { useCustomOptionsEditorContext } from '@odo/contexts/custom-options-editor';
import { useProductEditor } from '@odo/contexts/product-editor';
import { useEffect } from 'react';

const PreventLosingChanges = () => {
  const { hasChanges } = useProductEditor();
  const { hasUnsavedActions } = useCustomOptionsEditorContext();

  useEffect(() => {
    if (!hasChanges && !hasUnsavedActions) return;

    const confirmLeaving = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      return 'You will lose any unsaved changes to this deal.';
    };

    window.addEventListener('beforeunload', confirmLeaving);
    return () => window.removeEventListener('beforeunload', confirmLeaving);
  }, [hasChanges, hasUnsavedActions]);

  return null;
};

export default PreventLosingChanges;
