import {
  CheckboxGroup,
  Select,
  Switch,
} from '@odo/components/elements/form-fields';
import { Flex, Grid } from '@odo/components/elements/layout';
import SupplierSelector from '@odo/components/widgets/supplier-selector';
import {
  useChangeProduct,
  useCurrentProduct,
} from '@odo/contexts/product-editor';
import { useAttributeOptions } from '@odo/hooks/attributes';
import { AttributeCode } from '@odo/types/api';
import { Overscroll } from '@odo/screens/deal/editor/elements/styles';
import { validateProduct } from '@odo/screens/deal/editor/helpers';
import {
  buyerSectionValidators,
  campaignSectionValidators,
  supplierSectionValidators,
} from '@odo/screens/deal/editor/buyer-and-supplier/validators';
import ErrorBoundary from '@odo/components/widgets/error-boundary';
import SectionWrapper from '@odo/screens/deal/editor/elements/section-wrapper';
import { RebateDiscount } from '@odo/screens/deal/editor/widgets/shared-fields';
import type { SupplierOptionType } from '@odo/helpers/attributes';
import { getSupplierFromOption } from '@odo/helpers/attributes';

const SupplierSection = () => {
  const currentProduct = useCurrentProduct();
  const change = useChangeProduct();

  const status = validateProduct(
    currentProduct,
    supplierSectionValidators
  ).status;

  const setSupplier = (supplier?: SupplierOptionType) => {
    change({
      fieldId: 'supplier',
      label: 'Supplier',
      apply: to => {
        to.supplier = supplier ? getSupplierFromOption(supplier) : undefined;
      },
    });
  };

  const removeSupplier = () => setSupplier(undefined);

  return (
    <SectionWrapper title="Supplier" status={status}>
      <SupplierSelector
        label="Supplier"
        placeholder="Filter suppliers"
        supplier={currentProduct.supplier?.id}
        setSupplier={setSupplier}
        removeSupplier={removeSupplier}
        closeOnSelect
      />

      <RebateDiscount />

      <Switch
        label="Is Supplier New?"
        checked={!!currentProduct?.isSupplierNew}
        width="40px"
        onChange={() => {
          const value = !currentProduct?.isSupplierNew;
          change({
            fieldId: 'isSupplierNew',
            label: 'Is Supplier New?',
            apply: to => (to.isSupplierNew = value),
          });
        }}
      />
    </SectionWrapper>
  );
};

const BuyerSection = () => {
  const currentProduct = useCurrentProduct();
  const change = useChangeProduct();

  const status = validateProduct(currentProduct, buyerSectionValidators).status;

  const buyerOptions = useAttributeOptions(AttributeCode.buyer).filter(
    option =>
      !['none', 'lunchtime_deal', 'low_margin_deal'].includes(
        option.value.toLowerCase()
      )
  );
  const salesAssistantOptions = useAttributeOptions(
    AttributeCode.salesAssistant
  ).filter(option => !['none'].includes(option.value.toLowerCase()));
  const priorityOptions = useAttributeOptions(AttributeCode.priority);

  return (
    <SectionWrapper title="Buyer" status={status}>
      <Select
        label="Account Manager"
        value={currentProduct.buyer?.id || ''}
        onChange={e => {
          const value = e.target.value;
          const buyer = buyerOptions.find(buyer => buyer.value === value);
          change({
            fieldId: 'buyer',
            label: 'Account Manager',
            apply: to =>
              (to.buyer = buyer
                ? { id: buyer.value, label: buyer.label }
                : undefined),
          });
        }}
        options={[
          { id: '', value: '', label: 'Please select...' },
          ...buyerOptions.map(option => ({
            id: option.value,
            value: option.value,
            label: option.label,
          })),
        ]}
        required
      />

      <Select
        label="Loader"
        value={currentProduct.salesAssistant?.id || ''}
        onChange={e => {
          const value = e.target.value;
          const assistant = salesAssistantOptions.find(
            assistant => assistant.value === value
          );
          change({
            fieldId: 'salesAssistant',
            label: 'Loader',
            apply: to => {
              to.salesAssistant = assistant
                ? {
                    id: assistant.value,
                    label: assistant.label,
                  }
                : undefined;
            },
          });
        }}
        options={[
          { id: '', value: '', label: 'Please select...' },
          ...salesAssistantOptions.map(option => ({
            id: option.value,
            value: option.value,
            label: option.label,
          })),
        ]}
        required
      />

      <Select
        label="Priority"
        value={currentProduct.priority?.id.toString() || ''}
        onChange={e => {
          const value = e.target.value;
          const priority = priorityOptions.find(
            priority => priority.value === value
          );
          change({
            fieldId: 'priority',
            label: 'Priority',
            apply: to => {
              to.priority = priority
                ? {
                    id: parseInt(priority.value),
                    label: priority.label,
                  }
                : undefined;
            },
          });
        }}
        options={[
          { id: '', value: '', label: 'Please select...' },
          ...priorityOptions
            .filter(option => option.value.toLowerCase() !== 'none')
            .map(option => ({
              id: option.value,
              value: option.originalData.value,
              label: option.label,
            })),
        ]}
        required
      />
    </SectionWrapper>
  );
};

const CampaignSection = () => {
  const currentProduct = useCurrentProduct();
  const change = useChangeProduct();

  const status = validateProduct(
    currentProduct,
    campaignSectionValidators
  ).status;

  const dealTypeOptions = useAttributeOptions(AttributeCode.dealType);
  const campaignOptions = useAttributeOptions(AttributeCode.campaign);
  const campaignMailerOptions = useAttributeOptions(
    AttributeCode.campaignMailer
  );
  const platformOptions = useAttributeOptions(AttributeCode.platform);

  return (
    <SectionWrapper title="Campaign" status={status}>
      <CheckboxGroup
        label="Deal Type"
        options={dealTypeOptions
          .filter(dealType => dealType.value !== 'NONE' && !!dealType.value)
          .map(option => ({
            id: option.value,
            value: option.value,
            checked: (currentProduct.dealType || [])
              .map(({ id }) => id)
              .includes(option.value),
            label: option.label,
            onChange: e => {
              const label = option.label;
              const value = e.target.value;
              const checked = e.target.checked;
              change({
                fieldId: `dealType.${option.value}`,
                label: option.label,
                apply: to => {
                  to.dealType = [
                    ...(to.dealType || []).filter(({ id }) => id !== value),
                    ...(checked ? [{ id: value, label }] : []),
                  ];
                },
              });
            },
          }))}
      />

      <Switch
        label="Best Seller"
        checked={!!currentProduct?.isBestSeller}
        width="40px"
        onChange={e => {
          const checked = !!e.target.checked;
          change({
            fieldId: 'isBestSeller',
            label: 'Best Seller',
            apply: to => (to.isBestSeller = checked),
          });
        }}
      />

      <Select
        label="Campaign"
        value={currentProduct.campaign?.id || ''}
        onChange={e => {
          const value = e.target.value;
          const campaign = campaignOptions.find(
            campaign => campaign.value === value
          );
          change({
            fieldId: 'campaign',
            label: 'Priority',
            apply: to => {
              to.campaign = campaign
                ? {
                    id: campaign.value,
                    label: campaign.label,
                  }
                : undefined;
            },
          });
        }}
        options={[
          { id: '', value: '', label: 'Please select...' },
          ...campaignOptions.map(option => ({
            id: option.value,
            value: option.value,
            label: option.label,
          })),
        ]}
        required
      />

      <CheckboxGroup
        label="Include in Campaign Mailers"
        options={campaignMailerOptions
          .filter(mailer => mailer.value !== 'NONE' && !!mailer.value)
          .map(option => ({
            id: option.value,
            value: option.value,
            checked: (currentProduct.campaignMailer || [])
              .map(({ id }) => id)
              .includes(option.value),
            label: option.label,
            onChange: e => {
              const label = option.label;
              const value = e.target.value;
              const checked = e.target.checked;
              change({
                fieldId: `campaignMailer.${option.value}`,
                label: option.label,
                apply: to => {
                  to.campaignMailer = [
                    ...(to.campaignMailer || []).filter(
                      ({ id }) => id !== value
                    ),
                    ...(checked ? [{ id: value, label }] : []),
                  ];
                },
              });
            },
          }))}
      />

      <CheckboxGroup
        label="Platforms"
        options={platformOptions
          .filter(platform => platform.value !== 'NONE' && !!platform.value)
          .map(option => ({
            id: option.value,
            value: option.value,
            checked: (currentProduct.platform || [])
              .map(({ id }) => id)
              .includes(option.value),
            label: option.label,
            onChange: e => {
              const label = option.label;
              const value = e.target.value;
              const checked = e.target.checked;
              change({
                fieldId: `platform.${option.value}`,
                label: option.label,
                apply: to => {
                  to.platform = [
                    ...(to.platform || []).filter(({ id }) => id !== value),
                    ...(checked ? [{ id: value, label }] : []),
                  ];
                },
              });
            },
          }))}
      />
    </SectionWrapper>
  );
};

const BuyerAndSupplierScreen = () => (
  <ErrorBoundary>
    <Grid gridTemplateColumns={['1fr', '1fr 1fr']} gap={[3, 4]}>
      <Flex flexDirection="column" gap={[3, 4]}>
        <SupplierSection />
        <BuyerSection />
      </Flex>
      <CampaignSection />
    </Grid>
    <Overscroll />
  </ErrorBoundary>
);

export default BuyerAndSupplierScreen;
