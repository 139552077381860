import { useState, type ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { version, semVer } from '../../../config';
import metadata from 'buildData.json';
import client from '@odo/services/urql';
import { Provider as UrqlProvider } from 'urql';
import type { SpaceProps } from '@odo/lib/styled';
import styled, { compose, space } from '@odo/lib/styled';
import { cssColor } from '@odo/utils/css-color';
import { Grid, GridItem } from '@odo/components/elements/layout/grid';
import { conditionalJoin } from '@odo/utils/string';
import { Flex } from '@odo/components/elements/layout/flex';
import Button from '@odo/components/elements/button';
import { Text } from '@odo/components/elements/typography';
import { exportApiLogs } from '@odo/data/api-logs/cache';
import Dropdown from '@odo/components/widgets/dropdown';
import {
  FaListUl as IconMenu,
  FaCube as IconDashboard,
  FaPlus as IconCreate,
  FaSearch as IconSearch,
  FaClipboardList as IconExport,
  FaSignOutAlt as IconLogout,
  FaSun as IconLightMode,
  FaMoon as IconDarkMode,
} from 'react-icons/fa';
import './global.css';

// NOTE: for now we're adding a minimal typescript wrapper around RedPandas userDataSource hook.
// TODO: replace userDataSource with our own typescript implementation
import { useUserDataSource } from 'hooks/userDataSource';
import Dialog from '@odo/components/widgets/dialog';

// NOTE: this type actually has more going on.
// but I've decided to only add the fields that we're using.
interface UserDataSource {
  actions: {
    logout: (withNav?: boolean) => void;
  };
}

const isUserDataSource = (
  dataSource: UserDataSource | unknown
): dataSource is UserDataSource =>
  typeof (dataSource as UserDataSource) === 'object' &&
  typeof (dataSource as UserDataSource).actions === 'object' &&
  typeof (dataSource as UserDataSource).actions.logout === 'function';

const useTypedUserDataSource = () => {
  const user: UserDataSource | unknown = useUserDataSource();
  if (!isUserDataSource(user)) return undefined;
  return user;
};

/**
 * Our actual component code begins here.
 */
const Header = styled(Grid)`
  z-index: 20;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  pointer-events: none;
`;

const Footer = styled.footer`
  position: fixed;
  z-index: 7;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid ${cssColor('border')};
  background: ${cssColor('foreground')};
  box-shadow: 1px 2px 4px -2px hsl(240deg 33.33% 20% / 25%),
    1px 2px 8px -2px hsl(240deg 33.33% 20% / 10%);
`;

export const NavItem = styled(Flex)`
  box-shadow: 1px 2px 4px -2px hsl(240deg 33.33% 20% / 25%),
    1px 2px 8px -2px hsl(240deg 33.33% 20% / 10%);
  cursor: pointer;
  pointer-events: all;
`;

NavItem.defaultProps = {
  bg: cssColor('foreground'),
  color: cssColor('text'),
  flexDirection: 'row',
  alignItems: 'center',
  borderRadius: '32px',
  gap: '12px',
  px: 1,
  py: 1,
};

const MenuButton = styled(NavItem)`
  user-select: none;

  & .icon {
    display: none;
    margin-right: 8px;
  }
  @media screen and (min-width: 48em) {
    & .icon {
      display: inline-block;
    }
  }
`;

const MenuInner = styled.aside`
  padding-top: 4px;

  ul {
    list-style-type: none;
    padding: 8px 0;
    margin: 0;
  }

  ul:not(:first-child) {
    border-top: 1px solid hsl(240deg 33.33% 93%);
  }

  li {
    cursor: pointer;
    position: relative;
    &:hover {
      background: ${cssColor('palette-blue-muted')};
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 3px;
        background-color: ${cssColor('palette-blue')};
      }
    }
  }

  .meta {
    display: inline-block;
    padding: 6px 12px;
    font-family: monospace;
    font-size: 10px;
    color: ${cssColor('grey')};
  }
`;

type ColorMode = 'light' | 'dark' | 'system';

/**
 * Shamelessly stolen from Josh Comeau
 * @see https://www.joshwcomeau.com/react/dark-mode/
 *
 * As well as this RFC for Material UI:
 * @see https://github.com/mui/material-ui/issues/27651
 *
 * We're gonna have the localStorage check hardcoded in raw JS in our index.html file.
 * In our colors.css file we'll update the CSS variables based either on the set mode or the media query.
 * Then here we'll determine the same values for initial state, and allow the user to change the setting.
 */
const getInitialColorMode = (): ColorMode => {
  const persistedColorPreference = window.localStorage.getItem('color-mode');

  // If the user has explicitly chosen light or dark, let's use it.
  if (
    persistedColorPreference === 'light' ||
    persistedColorPreference === 'dark'
  ) {
    return persistedColorPreference;
  }

  // If they have explicitly asked to use system preferences, we'll check the media query
  if (persistedColorPreference === 'system') {
    const mql = window.matchMedia('(prefers-color-scheme: dark)');
    const hasMediaQueryPreference = typeof mql.matches === 'boolean';
    if (hasMediaQueryPreference) {
      return mql.matches ? 'dark' : 'light';
    }
  }

  // If they haven't made any explicit decisions, we'll default to 'light'.
  return 'light';
};

const MenuContent = ({
  close,
  hasUser,
  logout,
}: {
  close: () => void;
  hasUser: boolean;
  logout: () => void;
}) => {
  const navigate = useNavigate();

  const [colorMode, setColorMode] = useState(() => getInitialColorMode());

  const go = (to: string) => {
    navigate(to);
    close();
  };

  return (
    <MenuInner>
      <ul>
        <li>
          <Button
            hue="grey"
            variant="flat"
            borderRadius={0}
            width="100%"
            justifyContent="flex-start"
            gap={[2, 3]}
            onClick={() => go('/')}
          >
            <IconDashboard size={14} />
            Dashboard
          </Button>
        </li>
        <li>
          <Button
            hue="grey"
            variant="flat"
            borderRadius={0}
            width="100%"
            justifyContent="flex-start"
            gap={[2, 3]}
            onClick={() => go('/deals/create')}
          >
            <IconCreate size={14} />
            Create Deal
          </Button>
        </li>
        <li>
          <Button
            hue="grey"
            variant="flat"
            borderRadius={0}
            width="100%"
            justifyContent="flex-start"
            gap={[2, 3]}
            onClick={() => go('/deals/search')}
          >
            <IconSearch size={14} />
            Search Deals
          </Button>
        </li>
      </ul>

      <ul>
        <li>
          <Button
            hue="grey"
            variant="flat"
            borderRadius={0}
            width="100%"
            justifyContent="flex-start"
            gap={[2, 3]}
            onClick={() => exportApiLogs()}
          >
            <IconExport size={14} />
            Export Logs
          </Button>
        </li>
        {/* TODO: this button is disabled for now coz the dark theme isn't quite ready for use yet. */}
        {/* <li>
          <Button
            hue="grey"
            variant="flat"
            borderRadius={0}
            width="100%"
            justifyContent="flex-start"
            gap={[2, 3]}
            onClick={() => {
              const nextMode = colorMode === 'light' ? 'dark' : 'light';
              localStorage.setItem('color-mode', nextMode);
              document.body.setAttribute('data-color-mode', nextMode);
              setColorMode(nextMode);
            }}
          >
            {colorMode === 'dark' ? (
              <IconDarkMode size={14} />
            ) : (
              <IconLightMode size={14} />
            )}
            Color Mode
          </Button>
        </li> */}
        {!!hasUser && (
          <li>
            <Button
              hue="grey"
              variant="flat"
              borderRadius={0}
              width="100%"
              justifyContent="flex-start"
              gap={[2, 3]}
              onClick={logout}
            >
              <IconLogout size={16} />
              Logout
            </Button>
          </li>
        )}
      </ul>

      <span className="meta">
        {version} · {`${semVer}/c${metadata.commitRevision}`}
        {/* NOTE: this is a sample for what I ultimately want this meta to look like */}
        {/* version · branch · commit hash */}
        {/* 12.13.1 · development · ca75b1f */}
      </span>
    </MenuInner>
  );
};

const MainMenu = () => {
  const user = useTypedUserDataSource();
  const [confirmLogout, setConfirmLogout] = useState(false);

  return (
    <>
      <Dropdown
        offset={{ crossAxis: -8, mainAxis: 4 }}
        placement="bottom-start"
        content={({ close }) => (
          <MenuContent
            close={close}
            hasUser={!!user}
            logout={() => setConfirmLogout(true)}
          />
        )}
      >
        <MenuButton>
          <img
            src="/assets/odo-logo-80.png"
            alt="ODO Logo"
            style={{ width: '34px', borderRadius: '50%' }}
          />
          <IconMenu size={25} className="icon" color={cssColor('grey-blue')} />
        </MenuButton>
      </Dropdown>

      {!!user && (
        <Dialog
          title="Log out"
          isOpen={confirmLogout}
          close={() => setConfirmLogout(false)}
        >
          <Flex flexDirection="column" gap={3}>
            <Text>Are you sure you want to log out?</Text>
            <Flex justifyContent="space-between" gap={3}>
              <Button
                hue="grey"
                variant="flat"
                onClick={() => setConfirmLogout(false)}
              >
                Cancel
              </Button>
              <Button
                hue="blue"
                variant="solid"
                onClick={() => user.actions.logout(false)}
              >
                Confirm
              </Button>
            </Flex>
          </Flex>
        </Dialog>
      )}
    </>
  );
};

const ContextMenuItems = ({
  hasNav,
  title,
  toolbar,
}: {
  hasNav?: boolean;
  title?: ReactNode;
  toolbar?: ReactNode;
}) => (
  <>
    {(!!title || !!toolbar) && (
      <GridItem
        pr={[0, 3]}
        gridColumn={[hasNav ? '1 / 3' : '2', '2']}
        gridRow={hasNav ? '2' : '1'}
      >
        <Grid
          gap={[2, 3]}
          gridTemplateColumns={conditionalJoin([
            ['auto', !!title],
            ['auto', !!toolbar],
          ])}
          justifyContent={
            !!title && !!toolbar
              ? 'space-between'
              : !!title
              ? 'flex-start'
              : 'flex-end'
          }
        >
          {title}
          {toolbar}
        </Grid>
      </GridItem>
    )}
  </>
);

type AppMainProps = SpaceProps;

const AppMain = styled.main<AppMainProps>`
  background: ${cssColor('background')};
  ${compose(space)}
`;

AppMain.defaultProps = {
  p: [2, 3],
};

const AppInner = ({ children }: { children: ReactNode }) => (
  <AppMain>{children}</AppMain>
);

const AppContainer = ({
  children,
  title,
  toolbar,
  nav,
  footer,
}: {
  children: ReactNode;
  hasNav?: boolean;
  title?: ReactNode;
  toolbar?: ReactNode;
  nav?: ReactNode;
  footer?: ReactNode;
}) => {
  const hasNav = !!nav;

  return (
    <UrqlProvider value={client}>
      <Header
        gap={[2, 3]}
        pt={[2, hasNav ? 0 : 3]}
        px={[2, 0]}
        gridTemplateColumns="auto 1fr"
        gridTemplateRows={conditionalJoin([['auto', hasNav], 'auto'])}
        alignItems={['flex-start', 'center']}
      >
        {hasNav && (
          <GridItem gridColumn={['2', '1 / 3']} gridRow="1">
            {nav}
          </GridItem>
        )}

        <GridItem
          gridColumn="1"
          gridRow={['1', hasNav ? '2' : '1']}
          width="max-content"
          pl={[0, 3]}
        >
          <MainMenu />
        </GridItem>

        <ContextMenuItems hasNav={hasNav} title={title} toolbar={toolbar} />
      </Header>

      <AppInner>{children}</AppInner>

      {!!footer && <Footer>{footer}</Footer>}
    </UrqlProvider>
  );
};

export default AppContainer;
