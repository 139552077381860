import type { ReactNode } from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { produce } from 'immer';
import type { EditorProductInterface } from '@odo/types/portal';
import { useProduct } from '@odo/contexts/product-new';
import type { ChangeLock, EventListener } from './types';
import { EventType } from './types';
import {
  type ChangeInput,
  type ProductChange,
  type ProductEditorContextType,
} from './types';
import ProductEditorContext from './context';
import uuid from '@odo/utils/uuid';
import { timer, dismiss, notification } from '@odo/utils/toast';
import { Text } from '@odo/components/elements/typography';
import { cssColor } from '@odo/utils/css-color';

const RESET_TIMER = 15000;
const RESET_TOAST_ID = 'reset-product-editor-changes';
const LOCK_TOAST_ID = 'lock-product-editor-changes';

const showLockNotice = (reason?: string) => {
  notification(
    <>
      <Text>Editing is locked{reason ? `: "${reason}"` : ''}.</Text>
      <Text mt={1} fontSize={0} color={cssColor('text-muted')}>
        You can reload to clear the lock, but you risk losing any unsaved
        changes.
      </Text>
    </>,
    {
      id: LOCK_TOAST_ID,
      duration: Infinity,
      messageOptions: { dismissible: true },
    }
  );
};

const ProductEditorProvider = ({ children }: { children: ReactNode }) => {
  const product = useProduct();
  const [currentProduct, setCurrentProduct] = useState<EditorProductInterface>(
    () => produce(product, draft => draft)
  );

  const changesRef = useRef<ProductChange[]>([]);
  const nextResetIdxRef = useRef<number | undefined>();

  const canResetRef = useRef(false);
  const [canReset, setCanReset] = useState(false);
  const [reapplyChanges, setReapplyChanges] = useState(false);

  const lockRef = useRef<ChangeLock>({ status: false });

  const listeners = useRef<EventListener[]>([]);

  const addEventListener = useCallback(
    (event: EventListener) => listeners.current.push(event),
    []
  );

  const removeEventListener = useCallback(
    (event: EventListener) =>
      listeners.current.filter(
        l => l.type !== event.type && l.callback !== event.callback
      ),
    []
  );

  /**
   * Add and apply a new change.
   *
   * This change is added to an array stored in a MutableRefObject.
   * Then it's applied which will update the product in state causing a re-render.
   */
  const change: ProductEditorContextType['change'] = useCallback(
    (change: ChangeInput) => {
      if (lockRef.current.status) {
        showLockNotice(lockRef.current.reason);

        // fake object to suppress errors without having to conditionally check for the undo function everywhere
        return { undo: () => void 0 };
      }

      // we use a ref that duplicates the state to avoid re-creating this function
      if (!canResetRef.current) {
        setCanReset(true);
        canResetRef.current = true;
      }

      // generate a unique ID for undoing the change
      const id = uuid();

      const nextChange = { ...change, id };

      // de-dupe by fieldId and add the new change
      changesRef.current = [
        ...changesRef.current.filter(
          ({ fieldId }) => fieldId !== change.fieldId
        ),
        nextChange,
      ];

      setCurrentProduct(
        produce(nextProduct => {
          change.apply(nextProduct, change.meta);
          return nextProduct;
        })
      );

      // trigger event listeners NOTE: this runs before our return. I don't think it's a problem, but be aware.
      listeners.current.forEach(
        ({ type, callback }) =>
          type === EventType.change && callback(nextChange)
      );

      // we expose an undo function for individual changes
      const response: ReturnType<ProductEditorContextType['change']> = {
        undo: () => {
          changesRef.current = [
            ...changesRef.current.filter(({ id: changeId }) => changeId !== id),
          ];

          setReapplyChanges(true);
        },
      };

      return response;
    },
    []
  );

  /**
   * Reset all changes.
   *
   * Reverts the product back to it's original form.
   * And then leaves the user a short time to undo the reset.
   * Once that time has passed without being cancelled, we'll clear the changes list.
   */
  const reset = useCallback(() => {
    setCurrentProduct(produce(product, draft => draft));
    setCanReset(false);
    canResetRef.current = false;

    // store a reset marker
    nextResetIdxRef.current = changesRef.current.length;

    const undo = () => {
      // re-apply all changes
      setCurrentProduct(
        produce(product, nextProduct => {
          changesRef.current.forEach(change => {
            change.apply(nextProduct, change.meta);
          });
          return nextProduct;
        })
      );

      setCanReset(true);
      canResetRef.current = true;
      nextResetIdxRef.current = undefined;

      // trigger event listeners
      listeners.current.forEach(
        ({ type, callback }) => type === EventType.undoReset && callback()
      );
    };

    const finalize = () => {
      // run any cleanup functions
      changesRef.current
        .slice(0, nextResetIdxRef.current)
        .forEach(({ cleanup, meta }) => !!cleanup && cleanup(meta));

      // remove all changes up to the reset index (but keep anything that happened since)
      changesRef.current = changesRef.current.slice(nextResetIdxRef.current);
      nextResetIdxRef.current = undefined;

      // trigger event listeners
      listeners.current.forEach(
        ({ type, callback }) => type === EventType.finalizeReset && callback()
      );
    };

    // kick off a timer to allow the user to undo the reset.
    const toastId = timer('You have reset all your unsaved changes', {
      timeInMs: RESET_TIMER,
      timedOut: finalize,
      id: RESET_TOAST_ID,
      messageOptions: {
        onDismiss: finalize,
        action: {
          label: 'Undo',
          callback: () => {
            undo();
            dismiss(toastId);
          },
        },
      },
    });

    // trigger event listeners
    listeners.current.forEach(
      ({ type, callback }) => type === EventType.reset && callback()
    );
  }, [product]);

  /**
   * Get our changes.
   *
   * Because they aren't stored in state we instead have a getter function.
   *
   * NOTE: this will return all changes since the last reset even if it hasn't been finalized.
   */
  const getChanges = useCallback(() => {
    return changesRef.current.slice(nextResetIdxRef.current);
  }, []);

  /**
   * Control the change lock.
   *
   * Can be used to prevent changes while saving or doing other tasks that shouldn't be interfered with.
   */
  const setLock = useCallback(
    (status: boolean, options?: Omit<ChangeLock, 'status'>) => {
      dismiss(LOCK_TOAST_ID);

      lockRef.current = { status, ...options };

      status && options?.withNotice && showLockNotice(lockRef.current.reason);

      // trigger event listeners
      listeners.current.forEach(({ type, callback }) => {
        if (
          (status && type === EventType.lock) ||
          (!status && type === EventType.unlock)
        ) {
          callback();
        }
      });
    },
    []
  );

  const resetEditor = useCallback(() => {
    changesRef.current = [];
    canResetRef.current = false;
    lockRef.current = { status: false };
    setCanReset(false);
    setReapplyChanges(false);
  }, []);

  /**
   * Re-apply changes if the underlying product has changed from above (which we hope it shouldn't).
   */
  useEffect(() => {
    setCurrentProduct(
      produce(product, nextProduct => {
        changesRef.current.forEach(change => {
          change.apply(nextProduct, change.meta);
        });
        return nextProduct;
      })
    );
  }, [product]);

  /**
   * Re-apply changes on demand when we've flagged the state for it.
   *
   * This is currently used to allow aborting of a single change from outside of the provider.
   * At which stage we need to be able to perform this re-apply.
   * But we wouldn't necessarily have access to all the needed state.
   *
   * We've done this in a useEffect to avoid having ANY dependencies for our change function.
   */
  useEffect(() => {
    if (!reapplyChanges) return;

    setCurrentProduct(
      produce(product, nextProduct => {
        // if there are no more changes, then disable reset
        if (changesRef.current.length === 0) {
          setCanReset(false);
          canResetRef.current = false;
        }

        changesRef.current.forEach(change => {
          change.apply(nextProduct, change.meta);
        });

        return nextProduct;
      })
    );

    setReapplyChanges(false);
  }, [product, reapplyChanges]);

  const value: ProductEditorContextType = useMemo(
    () => ({
      currentProduct,
      change,
      canReset,
      // NOTE: for now this is based on the same underlying logic, but that might change in future.
      hasChanges: canReset,
      reset,
      getChanges,
      setLock,
      resetEditor,
      addEventListener,
      removeEventListener,
    }),
    [
      currentProduct,
      change,
      canReset,
      reset,
      getChanges,
      setLock,
      resetEditor,
      addEventListener,
      removeEventListener,
    ]
  );

  return (
    <ProductEditorContext.Provider value={value}>
      {children}
    </ProductEditorContext.Provider>
  );
};

export default ProductEditorProvider;
