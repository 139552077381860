export const SURCHARGE_WEIGHT_KEY = 'WEIGHT_AREA';
export const SURCHARGE_INSURANCE_KEY = 'INSURANCE';

export const ACCEPTED_IMAGE_TYPES = [
  'image/gif',
  'image/png',
  'image/jpeg',
  'image/webp',
];

export const POTENTIAL_PROFIT_TOOLTIP_COPY =
  'The potential profit based on weighted margin multiplied by the original stock.';
export const WEIGHTED_MARGIN_TOOLTIP_COPY =
  'The weighted margin which takes the popularity of payment methods into account when calculating a per unit average.';
